import { put, takeLatest } from 'redux-saga/effects';
import setNotification from '../helpers/notifications';
import addPaymentCardAndSetDefault from '../services/api/actions/addPaymentCardAndSetDefault';
import renewSubscription from '../services/api/actions/renewSubscription';
import upgradeSubscription from '../services/api/actions/upgradeSubscription';
import makeInitialOrder from '../services/api/actions/makeInitialOrder';
import recoverEmail from '../services/api/actions/recoverEmail';
import passwordReset from '../services/api/actions/passwordReset';
import forgotUserName from '../services/api/actions/forgotUserName';
import getToken from '../services/api/actions/getToken';
import getRedirectionToken from '../services/api/actions/getRedirectionToken';
import signUp from '../services/api/actions/signUp';
import getTokenFinal from '../services/api/actions/getTokenFinal';
import deleteBillAddress from '../services/api/actions/deleteBillAddress';
import updateBillAddress from '../services/api/actions/updateBillAddress';
import addPaymentCard from '../services/api/actions/addPaymentCard';
import addBillAddress from '../services/api/actions/addBillAddress';
import ordersPurchase from '../services/api/actions/ordersPurchase';
import getCouponById from '../services/api/actions/getCouponById';
import createPayment from '../services/api/actions/createPayment';
import removeCard from '../services/api/actions/removePaymentMethod';

import { setLoader } from '../store/actions';
import changeEmail from '../services/api/actions/changeEmail';
import decryptBackOfficeToken from '../services/api/actions/decryptBackOfficeToken';
import validateCardAndAdd from '../services/api/actions/validateCardAndAdd';
import updatePaymentCard from '../services/api/actions/updatePaymentCard';
import phoneVerify from '../services/api/actions/phoneVerify';
import getSignedAgreement from '../services/api/actions/getSignedAgreement';
import addAgreement from '../services/api/actions/addAgreement';
import calculatePrice from '../services/api/actions/calculatePrice';
import { isClient } from '../helpers/utils';
import { pageLinks } from '../helpers/navigation';
import changeProfileInfo from '../services/api/actions/changeProfileInfo';

function* onAxiosError(action) {
  yield put(setLoader(false));
  const CalcPriceCheckNoErrorPages = [
    pageLinks.preCheckout,
    pageLinks.signUp2,
    pageLinks.signUp3,
    pageLinks.signUp3,
    pageLinks.signUp4,
    pageLinks.checkoutSuccess,
    `${pageLinks.checkoutSuccess}/`,
  ];
  if (
    action.type === calculatePrice.type.error &&
    isClient &&
    CalcPriceCheckNoErrorPages.includes(window?.location.pathname)
  ) {
    return;
  }

  const messageResponse = action?.error?.response?.data?.message;
  let text;
  try {
    if (Array.isArray(messageResponse)) {
      const getVelue = (val) =>
        val
          .map((item) => Object.values(item?.constraints))
          .reduce((acc, value) => `${acc} ${value} `, '');

      text =
        typeof messageResponse[0] === 'string' ? messageResponse[0] : getVelue(messageResponse);
    }

    if (typeof messageResponse === 'string') {
      text = messageResponse;
    }

    if (
      action.error.response &&
      action.error.response.data.statusCode === 401 &&
      !action.error.response.data.message
    ) {
      return;
    }

    if (action.type === addBillAddress.type.error || action.type === updateBillAddress.type.error) {
      const inValid = text.includes('is invalid');
      const notExist = text.includes('not faund');
      if (inValid || notExist) {
        return;
      }
    }
  } catch (e) {
    text = null;
  }
  setNotification('error', {
    message: text,
    title: 'Error',
  });
}

const errorConstants = [
  addPaymentCardAndSetDefault.type.error,
  renewSubscription.type.error,
  phoneVerify.type.error,
  upgradeSubscription.type.error,
  makeInitialOrder.type.error,
  recoverEmail.type.error,
  passwordReset.type.error,
  forgotUserName.type.error,
  getToken.type.error,
  getRedirectionToken.type.error,
  signUp.type.error,
  getTokenFinal.type.error,
  signUp.type.error,
  getTokenFinal.type.error,
  deleteBillAddress.type.error,
  updateBillAddress.type.error,
  addBillAddress.type.error,
  removeCard.type.error,
  addPaymentCard.type.error,
  ordersPurchase.type.error,
  changeEmail.type.error,
  getCouponById.type.error,
  createPayment.type.error,
  decryptBackOfficeToken.type.error,
  validateCardAndAdd.type.error,
  updatePaymentCard.type.error,
  getSignedAgreement.type.error,
  addAgreement.type.error,
  calculatePrice.type.error,
  changeProfileInfo.type.error,
];

export default function* authSaga() {
  yield takeLatest([errorConstants], onAxiosError);
}
