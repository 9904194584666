/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './static/style-lib/loading.min.css';
import './src/styles/app.global.scss';
import { createNavigateTo, pageLinks } from './src/helpers/navigation';
import wrapWithProvider from './wrap-with-provider';
import { isAddonInstance } from './src/helpers/utils';

const { registerLinkResolver } = require('@prismicio/gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');

registerLinkResolver(linkResolver);

export const wrapRootElement = wrapWithProvider;

export const onInitialClientRender = () => {
  if (window.location.pathname.includes('/enrollment')) {
    setTimeout(createNavigateTo(pageLinks.home), 0);
  }
};

export const onServiceWorkerUpdateReady = () => {
  // eslint-disable-next-line no-alert
  const answer = window.confirm(
    // eslint-disable-next-line no-useless-concat
    `This application has been updated. ` + `Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};
