import { navigate } from 'gatsby';

const shopLinks = {
  home: '/',
  signUp2: '/sign-up-2',
  signUp3: '/sign-up-3',
  signUp4: '/sign-up-4',
  preCheckout: '/pre-checkout',
  pricing: '/pricing',
  pricingAlias: '/pricing:alias',
  pricingLive: '/pricing/live',
  pricingLearn: '/pricing/learn',
  pricingElite: '/pricing/elite',
  pricingNitro: '/pricing/nitro',
  shoppingCart: '/shopping-cart',
  membershipTravel: '/membership/travel',
  membershipLearn: '/membership/learn',
  membershipLifestyle: '/membership/lifestyle',
  membershipConcierge: '/membership/concierge',
  verification: '/verification',
  verificationSuccess: '/verification-success',
  blog: '/blog',
  aboutUs: '/about-us',
  promote: '/promote',
  joinUs: '/join-us',
  contactUs: '/contact-us',
  account: '/',
  checkout: '/checkout',
  checkoutSuccess: '/checkout-result',
  additionalToPlan: '/',
  privacy: '/privacy-policy',
  gdpr: '/gdpr-policy',
  terms: '/terms-of-use',
  refund: '/refund-policy',
  policiesAndProcedures: '/policies-and-procedures',
  compensationPlan: '/compensation-plan-agreement',
  cancellation: '/cancellation-policy',
  policies: '/policies-and-procedures',
  signIn: '/',
  forgotPassword: '/forgot-password',
  forgotUsername: '/forgot-username',
  forgotPasswordSuccess: '/forgot-password-success',
  forgotUsernameSuccess: '/forgot-username-success',
  createNewPassword: '/create-new-password',
  becomeIBP: '/become-ibp',
  registrationSuccess: '/registration-success',
  regions: '/regions',
  registration: '/registration',
  addons: '/addons',
  preAuthorisation: '/pre-authorisation',
  cookiePolicy: '/cookies-policy',
  spam: '/spam-policy',
  compensation: '/compensation-plan',
  policiesProcedures: '/policies-and-procedures',
  amlPolicy: '/aml-policy',
  ibi: '/ibi-agreement',
  earningInfo: '/earning-info',
  earningDisclosure: '/be-earning-disclosure-statement',
  codeEthics: '/code-of-ethics',
  eduVideos: '/educational-compliance-videos',
  signAgreement: '/signagreement',
  agreement: '/agreement',
  otp: '/otp',
};

const addonLinks = {
  ...shopLinks,
  pricing: '/',
};

const getLinks = () => {
  if (process.env.GATSBY_INSTANCE_NAME === 'addon') {
    return addonLinks;
  }
  return shopLinks;
};

export const externalLinks = {
  alternativePaymentMethod: 'https://be-unrivaled.com',
};

export const createNavigateTo = (to = '/', options) => () =>
  setTimeout(() => navigate(to, options), 0);

export const pageLinks = getLinks();
