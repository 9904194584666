import * as Constants from '../constants';
import { actionCreator } from '../utils';

export const selectProduct = (name) => actionCreator(Constants.SELECT_PRODUCT, name);
export const startSSO = () => actionCreator(Constants.START_SSO);
export const updateUserDetailsInfo = (newData) =>
  actionCreator(Constants.CHANGE_USER_DETAILS, newData);
export const addToCart = (type, data, paymentAction, ...rest) =>
  actionCreator(Constants.ADD_TO_CART, {
    type,
    data,
    paymentAction,
    ...rest,
  });

export const removeFromCart = (id) => actionCreator(Constants.REMOVE_FROM_CART, id);
export const setLoader = (flag) => actionCreator(Constants.SET_LOADER, flag);
export const setLanguages = (languages) => actionCreator(Constants.SET_LANGUAGES, languages);
export const logOut = () => actionCreator(Constants.LOG_OUT_USER);
export const clearShoppingCart = () => actionCreator(Constants.CLEAR_SHOPPING_CART);
export const selectCoupon = (id) => actionCreator(Constants.SELECT_COUPON, id);
export const showReferralHeader = (flag) => actionCreator(Constants.SHOW_REFERRALS_HEADER, flag);
export const changeLanguage = (lang) => actionCreator(Constants.CHANGE_LANGUAGE, lang);
export const hideAddonPanel = () => actionCreator(Constants.HIDE_ADDON_PANEL);

export const selectPaymentMethodFromSaved = (data) =>
  actionCreator(Constants.SELECT_PAYMENT_METHOD_FROM_SAVED, data);

export const checkCoupon = (coupon) => actionCreator(Constants.CHECK_COUPON, coupon);
export const preselectCoupon = (coupon) => actionCreator(Constants.PRESELECTED_COUPON, coupon);

export const makeOrderWithSavedCart = (data) =>
  actionCreator(Constants.MAKE_ORDER_WITH_SAVED_CART, data);

export const makeOrderWithDLocalInstallment = (data) =>
  actionCreator(Constants.MAKE_ORDER_WITH_D_LOCAL_INSTALLMENT, data);

export const fetchRefferal = (id) => actionCreator(Constants.FETCH_REFFERIAL_INFO, id);

export const validateAddon = (addon) => actionCreator(Constants.VALIDATE_ADDON, addon);

export const setModal = (type) => actionCreator(Constants.SET_MODAL, type);
export const setSubmitCount = (count) => actionCreator(Constants.SET_SUBMIT_COUNT, count);
export const setModalError = (error) => actionCreator(Constants.SET_MODAL_ERROR, error);
export const startRegistration = (data) => actionCreator(Constants.REGISTER_USER, data);
export const registerUser = (verified) =>
  actionCreator(Constants.REGISTER_USER_AFTER_PHONE_VALIDATION, verified);
export const clearCouponData = () => actionCreator(Constants.CLEAR_SELECTED_COUPONS);

export const setExpectedSubscriptions = (data) =>
  actionCreator(Constants.SET_EXPECTED_SUBSCRIPTIONS, data);

export const toggleEmailCheckBanner = (flag) =>
  actionCreator(Constants.TOGGLE_CHECK_EMAIL_BANNER, flag);

export const saveReferralInfo = (params) => actionCreator(Constants.SAVE_REFERRAL_INFO, params);
export const recalculatePrice = () => actionCreator(Constants.RECALCULATE_SHOPPING_CART_PRICE);
export const clearUserDataOnNewEnrollmentInfo = () =>
  actionCreator(Constants.CLEAR_USER_DATA_ON_NEW_ENROLLMENT_INFO);

export const setUserStartCountry = (country) => actionCreator(Constants.SET_USER_COUNTRY, country);

export const setBannerRefferal = (flag) => actionCreator(Constants.SET_BANNER_REFFERAL, flag);
export const updateProducts = (country) => actionCreator(Constants.UPDATE_PRODUCT_LIST, country);
export const updatePaymentMethods = () => actionCreator(Constants.UPDATE_PAYMENT_METHODS);

export const emulateDefaultCardCreation = (data) =>
  actionCreator(Constants.EMULATE_CREATION_DEFAULT_CARD, data);

export const toggleAnnual = (isAnnually) => actionCreator(Constants.TOGGLE_ANNUAL, isAnnually);
export const setButtonLoaderType = (type) => actionCreator(Constants.SET_BUTTON_LOADER, type);
export const updateCardInfo = (card) => actionCreator(Constants.UPDATE_CARD_INFO, card);

export const setModalInfo = (data) => actionCreator(Constants.SET_ADDONS_MODAL_INFO, data);
export const redirectToOtherSite = (type) => actionCreator(Constants.START_REDIRECTION, type);
export const toggleLiveChat = (flag) => actionCreator(Constants.TOGGLE_LIVE_CHAT, flag);
export const showSkipVerificationButton = (flag) =>
  actionCreator(Constants.SHOW_SKIP_VERIFICATION_BUTTON, flag);
export const setAuditTrialData = (data) => actionCreator(Constants.SET_AUDIT_TRIAL, data);
export const setAuditTrailStatus = (data) => actionCreator(Constants.SET_AUDIT_TRAIL_STATUS, data);
export const updateProductInfo = () => actionCreator(Constants.UPDATE_PRODUCTS, { country: 'us' });
export const setAvailablePaymentMethods = (data) =>
  actionCreator(Constants.SET_PAYMENT_METHODS, data);
export const setCookiesBanner = (flag) => actionCreator(Constants.SET_COOKIES_BANNER, flag);

export const getSignedAgreement = () => actionCreator(Constants.GET_SIGNED_AGREEMENT);
export const toggleAgreementDataFlag = (flag) =>
  actionCreator(Constants.SET_AGREEMENT_DATA_FLAG, flag);
export const handleCheckAgreement = () => actionCreator(Constants.HANDLE_CHECK_AGREEMENT);
