import { createSelector } from 'reselect';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import {
  isEqualArrayStrings,
  transformCartToItems,
  isAddonInstance,
  isRetailAddonInstance,
} from '../../helpers/utils';
import productsResolver from '../../helpers/productsResolver';
import { selectCouponId } from './global';

const selectEntities = (state) => state.entities;

export const selectRefferalInfo = createSelector(
  selectEntities,
  (entities) => entities.userRefferal
);

export const selectEntityProducts = createSelector(selectEntities, (entities) => entities.products);

export const selectEntityUserDetails = createSelector(
  selectEntities,
  (entities) => entities.userDetails
);

export const selectEntityWallets = createSelector(selectEntities, (entities) => entities.wallets);

export const selectCalculatedPrice = createSelector(
  selectEntities,
  (entities) => entities.calculatedPrice
);

export const selectEntityUserSubscriptions = createSelector(
  selectEntities,
  (entities) => entities.userSubscriptions
);

export const selectExpectedSubscriptions = createSelector(
  selectEntities,
  (entities) => entities.expectedSubscriptions
);

export const selectExpectedSubscriptionIds = createSelector(
  selectExpectedSubscriptions,
  (expected) => {
    if (expected) {
      const items = transformCartToItems(expected.items);
      return uniqBy(items, (e) => e.planId).map((item) => item.planId);
    }
    return expected;
  }
);

export const selectAddons = createSelector(selectEntityProducts, (products) => {
  if (products?.length) {
    return products
      .map((item) => item.plans.map((plan) => ({ ...plan, product: { name: item.name } })))
      .reduce((acc, val) => [...acc, ...val])
      .map((item) => {
        const price = isRetailAddonInstance
          ? item.retailPrice || 0 + item.setupPrice
          : item.setupPrice;
        const { recurringPrice } = item;

        const rebillyPlanId = item.rebillyPlanIds[0];

        return { ...item, price, recurringPrice, rebillyPlanId };
      });
  }

  return [];
});

export const selectAddonsWithSubscriptions = createSelector(
  [selectAddons, selectEntityUserSubscriptions],
  (addons, boughtProducts) => {
    if (addons) {
      return addons.map((item) => {
        const boughtAddon =
          boughtProducts &&
          boughtProducts.find((product) => product.plans[0] === item.rebillyPlanIds[0]);

        const sku = item.skus[0];

        if (boughtAddon) {
          const expiresAt = moment(boughtAddon.expireAt).diff(moment(), 'd');
          const isExpired = expiresAt <= 0;
          const isRenew = !boughtAddon.plans[0].includes('luxe');
          const price = isRenew ? item.recurringPrice : item.price;
          return {
            ...item,
            expiresAt,
            isExpired,
            isRenew,
            currentSubscription: true,
            price,
            sku,
          };
        }

        return { ...item, sku };
      });
    }

    return addons;
  }
);

export const selectEntityCoupons = createSelector(
  [selectEntities, selectCouponId],
  (entities, selectedCouponId) => {
    const { coupons } = entities;

    if (coupons && coupons.length) {
      let list;
      if (coupons) {
        if (selectedCouponId.length) {
          list = coupons.map((item) => ({
            ...item,
            isApplied: selectedCouponId.includes(item.couponCode),
          }));
        }
      }

      return list && list.length ? list : coupons;
    }
    return coupons;
  }
);

export const selectEntityPaymentMethods = createSelector(
  selectEntities,
  (entities) => entities.paymentMethods
);

export const selectUserSubscriptions = createSelector(
  [selectEntityUserSubscriptions, selectExpectedSubscriptions],
  (boughtSubscriptions, expectedSubscriptions) =>
    (expectedSubscriptions && expectedSubscriptions.items) || boughtSubscriptions
);

export const selectEntityBillingDetails = createSelector(
  selectEntities,
  (entities) => entities.billingDetails
);

export const selectFilteredProducts = createSelector([selectEntityProducts], (products) =>
  productsResolver(products, false)
);

export const selectProductsWithSubscriptions = createSelector(
  [selectFilteredProducts, selectUserSubscriptions],
  (ibiProduct, subscriptions) => {
    try {
      if (ibiProduct && subscriptions && subscriptions.length) {
        const newProduct = {
          ...ibiProduct,
          plans: ibiProduct.plans.map((plan) => ({
            ...plan,
            isRenew: !!subscriptions?.find((sub) => sub?.planId === plan?.id),
          })),
        };

        return newProduct;
      }

      return ibiProduct;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('selectProductsWithSubscriptions', e);
    }

    return null;
  }
);

export const selectElectronicSignments = createSelector(
  selectEntities,
  (entities) => entities.electronicSignment
);

export const selectMetaSiteInfo = createSelector(selectEntities, (entities) => entities.metaInfo);

export const selectCountriesInRegion = createSelector(selectMetaSiteInfo, (meta) => {
  if (process.env.NODE_ENV !== `production`) {
    return ['us', 'kz', 'ua'];
  }

  if (meta && meta.currentInstance) {
    return meta.currentInstance?.countries
      ?.filter((item) => item?.code !== 'PH')
      ?.map((country) => country?.code?.toLowerCase());
  }

  return [];
});

export const selectLegalDocuments = createSelector(
  selectMetaSiteInfo,
  (meta) => meta?.legalDocuments
);

export const selectAuditTrial = createSelector(selectEntities, (entities) => entities.auditTrial);
