import { all, call, put, fork, takeLatest, take } from 'redux-saga/effects';
import { delay } from 'lodash';
import decryptBackOfficeToken from '../services/api/actions/decryptBackOfficeToken';
import {
  checkIfUserIsIbp,
  externalRedirect,
  INSTANCE_TYPES,
  isClient,
  setLanguageToLocalStorage,
} from '../helpers/utils';
import { getSearchParam } from '../helpers/url';
import { clearShoppingCart, fetchRefferal, setLoader, setUserStartCountry } from '../store/actions';
import { setTokens } from '../services/api/tokenHandler';
import getTokenFinal from '../services/api/actions/getTokenFinal';
import userDataSaga from './userDataSaga';
import signUp from '../services/api/actions/signUp';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import getUserDetails from '../services/api/actions/getUserDetails';

const isDevelopmentMode = process.env.NODE_ENV !== `production`;

function* singleSignOnSaga(redirectionToken) {
  const tempToken = getSearchParam('tempToken', window.location.href);
  const beBackOfficeToken = getSearchParam('Token', window.location.href);
  let token = redirectionToken || tempToken;
  const isEnrollment = isClient && window.location.href.includes('enrollment');

  if (isClient && isEnrollment) {
    const urlData = window.location.href.split('/');
    const isEnrolment = (el) => el === 'enrollment';
    const id = urlData?.findIndex(isEnrolment) + 1;
    const enrollment = urlData[id];
    yield put(fetchRefferal(enrollment));
  }

  if (beBackOfficeToken && beBackOfficeToken.length > 10) {
    const [response] = yield all([
      yield put(
        decryptBackOfficeToken
          .withQuery(
            `${encodeURIComponent(beBackOfficeToken)}?instanceType=${INSTANCE_TYPES.IBI_SHOP}`
          )
          .action()
      ),
    ]);

    if (response.payload) {
      token = {
        ...token,
        redirectionToken: response.payload.data.data.redirectionToken,
        accessToken: response.payload.data.data.redirectionToken,
        beBackOfficeToken,
      };

      const { redirectionUrl, wrongDomain } = response.payload.data.data;

      if (wrongDomain && !isDevelopmentMode) {
        externalRedirect(
          `https://${redirectionUrl}?Token=${encodeURIComponent(beBackOfficeToken)}`
        );
      }
    }

    if (response.error) {
      // yield put(push('/login'));
    }
  }

  if (token) {
    yield call(setTokens, token, '');
    yield delay(() => {}, 2000);
    yield put(
      getTokenFinal
        .withQuery(`?instanceType=${INSTANCE_TYPES.IBI_SHOP}&devMode=${isDevelopmentMode}`)
        .action()
    );

    const details = yield take(getUserDetails.type.success);
    const detailsData = details?.payload?.data?.data;
    const userRoles = detailsData?.roles;
    const lang = detailsData?.languageId;
    const country = detailsData?.country;

    if (lang) {
      setLanguageToLocalStorage(lang);
    }

    if (country) {
      yield put(setUserStartCountry(country));
    }

    if (userRoles && isClient) {
      const isAgreementRoute = window.location.href.includes('agreement');
      if (!isAgreementRoute) {
        yield call(createNavigateTo(pageLinks.signUp2));
      }
    }
  }
  if (!token) {
    yield call(userDataSaga);
  }
}

function* finalizeSignInNew(action) {
  const { payload } = action;

  localStorage.removeItem('persist:global');
  yield put(clearShoppingCart());

  const {
    userId,
    username,
    accessToken,
    refreshToken,
    // country,
    // wrongDomain,
    // redirectionUrl,
  } = payload.data.data;

  yield call(setTokens, { accessToken, refreshToken });

  if (isClient) {
    localStorage.setItem('UID', userId);
    localStorage.setItem('UN', username);
  }
  if (action.type === signUp.type.success) {
    yield call(createNavigateTo(pageLinks.registrationSuccess));
    yield put(setLoader(false));
  }

  yield fork(userDataSaga);
}

function* tokenSaga() {
  yield all([
    singleSignOnSaga(),
    yield takeLatest([getTokenFinal.type.success, signUp.type.success], finalizeSignInNew),
  ]);
}

export default tokenSaga;
